import React from "react";
import "./postCovid.css";
import Acupuncture from "../../Assets/acupuncture.webp";
import CS100 from "../../Assets/cs100.jpg";
import BiofeedbackTeraphy from "../../Assets/BiofeedbackTeraphy.jpg";
import Balloon from "../../Assets/Balloon.webp";
import AutomicNervousSystem from "../../Assets/AutomicNervousSystem.webp";

function PostCovid() {
  return (
    <>
      <div className="pcSyndromweIntro h-100 mb-5">
        <h1 className="text-center mb-5 mt-1">Post covid syndrome</h1>
        <h3 className="mplus2">Introduction :</h3>
        <p className=" text-left mplus2 mt-4">
          <span className="fw-bold">T</span>he realm of medical research,
          dysautonomia has emerged as a critical factor in understanding
          conditions like Myalgic Encephalomyelitis/Chronic Fatigue Syndrome
          (ME/CFS) and post COVID syndrome. Recent breakthroughs have
          highlighted the profound similarities between these conditions,
          shedding light on shared underlying mechanisms and treatment
          approaches. Leveraging advanced testing modalities and integrative
          medicine, our focus is on addressing autonomic dysfunction to enhance
          the quality of life for individuals affected by ME/CFS and post COVID
          syndrome.
        </p>
      </div>

      <div className="mecsfP d-md-flex">
        <div className="d-flex justify-content-center">
          <img
            src={Acupuncture}
            alt="acupuncture"
            className="mb-5 acupuncture"
          />
        </div>

        <div className="my-lg-4 mplus2">
          <h3 className="mb-4 ">
            Understanding ME/CFS and Post COVID Syndrome:
          </h3>
          <ul>
            <li className="mb-5">
              Dysautonomia as the Underlying Cause: Dysautonomia research has
              unveiled ME/CFS as a condition primarily rooted in autonomic
              imbalance, shedding light on the core pathology driving the
              illness.
            </li>
            <li>
              Similarities Between ME/CFS and Post COVID Syndrome: Recent
              research has highlighted striking parallels between ME/CFS and
              post COVID syndrome, indicating shared symptomatology and
              underlying mechanisms, particularly in relation to autonomic
              dysfunction.
            </li>
          </ul>
        </div>
      </div>

      <div className="mecsfP d-md-flex align-items-center ">
        <div className="my-lg-4 mplus2">
          <h3 className="mb-4">Advanced Testing Modalities:</h3>
          <ul>
            <li>
              Employing ANS and CS-100 Testing: Utilizing sophisticated testing
              modalities such as Autonomic Nervous System (ANS) assessments and
              CS-100 testing to identify specific abnormalities common to both
              ME/CFS and post COVID syndrome patients, enabling targeted
              intervention strategies.
            </li>
          </ul>
        </div>

        <div className="d-flex justify-content-center">
          <img src={CS100} alt="cs 100" className="mb-5 acupuncture" />
        </div>
      </div>

      <div className="d-md-flex BiofeedbackTeraphy position-relative align-items-center justify-content-center">
        <div className="d-flex justify-content-center ">
          <img
            src={BiofeedbackTeraphy}
            alt="Bio feedback Teraphy"
            className="BiofeedbackTeraphyImg mb-5"
          />
        </div>
        <div className="mplus2 ps-md-5 mt-lg-4 BiofeedbackTeraphyInfo">
          <h3 className="mb-4">Comprehensive Treatment Approaches:</h3>
          <ul>
            <li>
              Integrative Medicine and Autonomic Modulation: Drawing from the
              principles of integrative medicine and autonomic modulation,
              comprehensive treatment approaches have been developed to address
              the multifaceted nature of ME/CFS, focusing on restoring autonomic
              balance and improving overall well-being.
            </li>
          </ul>
        </div>
      </div>

      <div className="pcsManagement d-md-flex align-items-center mt-3 ">
        <div className="my-lg-4 mplus2 pcsManagementInfo">
          <h3 className="mb-4">
            Integration into Post COVID Syndrome Management:
          </h3>
          <p className="px-md-3">
            Recognizing Parallels and Implementing Treatment Modalities:
            Recognizing the shared features between ME/CFS and post COVID
            syndrome, efforts have been made to seamlessly integrate successful
            treatment modalities developed for ME/CFS into the management
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <img
            src={Balloon}
            alt="Balloon"
            className="mb-5 acupuncture balloon"
          />
        </div>
      </div>

      <div className="pcsManagementInt d-md-flex align-items-center ">
        <div className="d-flex justify-content-center">
          <img
            src={AutomicNervousSystem}
            alt="Automic Nervous System"
            className="mb-5 acupuncture AutomicNervousSystem"
          />
        </div>
        <div className="my-lg-4 mplus2 pcsManagementInfo ms-md-5">
          <h3 className="mb-4">
            Optimizing Management through Integrative Approach:
          </h3>
          <p className="px-md-3">
            Addressing Autonomic Dysregulation: Prioritizing the normalization
            of autonomic function as a cornerstone of treatment for both ME/CFS
            and post COVID syndrome, with interventions aimed at modulating
            autonomic activity and restoring physiological equilibrium.
          </p>
        </div>
      </div>
    </>
  );
}

export default PostCovid;
