import "./home.css";
import heartbg from "../../Assets/heart bg.png";
import doc from "../../Assets/Rectangle 3.png";
import patient2 from "../../Assets/section4DuoDesign.png";
import machine from "../../Assets/impella-heart-pump.webp";
import nervesImg from "../../Assets/Aneurysem.webp";
import neuronsImg from "../../Assets/iStock-509417559.webp";
import cs100 from "../../Assets/cs100.jpg";
import heartImg from "../../Assets/Balloon.webp";
import arDevice from "../../Assets/ARTERY DEVICE.webp";

function Home() {
  const sectionsData = [
    {
      title: "Use of Impella Percutaneous Heart Pump:",
      items: [
        "Utilization of Impella percutaneous heart pump in innovative protocols for revascularization and chronic heart failure management.",
        "Largest volume operator for Impella procedures in the Philadelphia area.",
      ],
      image: machine,
      imageAlt: "machine 1",
      justifyContent: "justify-content-around ",
      topImage: true,
    },
    {
      title: "Embolization Procedures and AAA Repairs:",
      items: [
        "Specialization in embolization procedures for life-threatening bleeding and vascular aneurysms.",
        "Minimally invasive percutaneous approach for Abdominal Aortic Aneurysm (AAA) repairs.",
      ],
      image: nervesImg,
      imageAlt: "",
      justifyContent: "justify-content-center middleRow",
    },
    {
      title: "Embolization Procedures and AAA Repairs:",
      items: [
        "Specialization in embolization procedures for life-threatening bleeding and vascular aneurysms.",
        "Minimally invasive percutaneous approach for Abdominal Aortic Aneurysm (AAA) repairs.",
      ],
      image: neuronsImg,
      imageAlt: "",
      justifyContent: "justify-content-center middleRow",
      reverse: true,
    },
    {
      title: "Use of Impella Percutaneous Heart Pump:",
      items: [
        "Utilization of Impella percutaneous heart pump in innovative protocols for revascularization and chronic heart failure management.",
        "Largest volume operator for Impella procedures in the Philadelphia area.",
      ],
      images: [arDevice, heartImg, cs100],
      imageAlts: ["machine 1", "machine 1", "machine 1"],
      justifyContent: "justify-content-around ",
    },
  ];

  const section4Content = [
    {
      title: "Treatment Programs:",
      items: [
        "Cardio-Diabetes Program",
        "Long COVID Syndrome Treatment Protocols",
      ],
    },
    {
      title: "Diabetic Limb Salvage:",
      items: ["Dysautonomia Treatment Protocols for Diabetic Limb Salvage"],
    },
    {
      title: "Referral Center and Reputation:",
      items: [
        "Leading Resource for Dysautonomia-related Consultation and Referral",
      ],
    },
    {
      title: "Treatment Programs:",
      items: [
        "Cardio-Diabetes Program",
        "Long COVID Syndrome Treatment Protocols",
      ],
    },
    {
      title: "Recognition and Affiliation:",
      items: ["Referral Center Recognition for Erythromelalgia Patients"],
    },
    {
      title: "Research Contributions:",
      items: ["Pioneering Dysautonomia Research and Treatment Approaches"],
    },
  ];

  function Section3({
    title,
    items,
    image,
    imageAlt,
    images,
    imageAlts,
    justifyContent,
    reverse,
    topImage,
  }) {
    return (
      <div
        className={`d-flex p-4 p-md-5 ${justifyContent} flex-column flex-md-row  align-items-center ${
          reverse ? "flex-md-row-reverse" : ""
        }`}
      >
        <div className="">
          <div>
            <h5 className="section2SubHead">{title}</h5>
            <ul>
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        {image && (
          <img
            src={image}
            alt={imageAlt}
            className={`${topImage ? "topImagesec3" : "Imagessec3"}`}
          />
        )}
        {images && (
          <div className="imagesDivSec3 d-flex justify-content-center align-itrems-center">
            {images.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={imageAlts[index]}
                className="bottomSec3"
              />
            ))}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="hoempage">
      <section className="landingSec d-flex flex-column flex-md-row flex-md-row-reverse justify-content-around align-items-center">
        <img src={heartbg} alt="heartBackground" className="heartbgHome" />
        <div className="d-flex flex-column">
          <h1 className="rencaHeading d-flex flex-column justify-content-center text-center">
            <span>RENU-CA RESEARCH</span>
            <span>INSTITUTE</span>
          </h1>
          <h1 className="renucaHeading2 p-3 p-sm-5 text-white fw-light">
            DYSAUTONOMIA -TYPE II DIABETES -ADVANCED HEART AND VASCULAR CENTER
          </h1>
        </div>
      </section>
      <section className="Establishmentsec position-relative p-4 p-md-5 d-flex flex-column align-items-center ">
        <div className="d-flex flex-column position-relative flex-md-row flex-md-row-reverse justify-content-around align-items-center">
          <img
            src={doc}
            alt="doctor folding hands"
            className="section2ImgHome m-4"
          />
          <div className="p-3 sectionContent">
            <h5 className="section2SubHead m-3">
              Clinical Research Institute Establishment
            </h5>
            <ul>
              <li>
                RENU-CA Research Institute founded by Dr. Ramesh Adiraju in
                2005.
              </li>
            </ul>

            <h5 className="section2SubHead m-3">Medical Expertise:</h5>
            <ul>
              <li>
                Extensive experience in coronary and endovascular interventions.
              </li>
              <li>
                Over 33 years of experience, performing over 6000 coronary and
                valvular procedures.
              </li>
              <li>
                Conducted over 1500 peripheral vascular and endovascular
                procedures.
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row m-4 justify-content-center justify-content-around align-items-center">
          <img src={patient2} alt="" className="section2Imgduo duo2" />
          <div className="sectionContent">
            <h5 className="section2SubHead m-3">Innovative Techniques:</h5>
            <ul>
              <li>
                Development of novel endovascular techniques for complex
                coronary and peripheral arterial disease.
              </li>
              <li>Interest in atherectomy to minimize stent usage.</li>
              <li>
                Development of aortic valvuloplasty technique using peripheral
                cutting balloons.
              </li>
              <li>
                Specialization in complex high-risk cardiovascular and
                peripheral vascular procedures.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="p-4 p-md-5">
        {sectionsData.map((section, index) => (
          <Section3 key={index} {...section} />
        ))}
      </section>

      <section className="section4Home ">
        <h3 className="section2SubHead text-center mb-5 mt-5">
          Clinical Programs, Limb Salvage, Attention Disorders, Referral Center,
          Recognition & Research
        </h3>
        <div className="d-flex flex-wrap justify-content-center align-items-center">
          {section4Content.map((subSection) => (
            <div className="clinicalProg my-5">
              <h5>◉ {subSection.title}</h5>
              {subSection.items.map((item) => (
                <ul>
                  <li>{item}</li>
                </ul>
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Home;
