import React, { useState } from "react";
import Navlinks from "./Navlinks";
import "./navbar.css";
import { Button, Offcanvas } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import Heart from "../Assets/heart.png";

function Navbar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="navbar py-1 px-3 ">
      <a href="/" className="text-decoration-none">
        <h2>
          Renu-ca <img src={Heart} alt="Heart" className="heartNav" />
        </h2>
      </a>

      {/* Mobile view components */}
      <div className="d-flex d-lg-none justify-content-end">
        <Button variant="dark" className="burger-menu btn" onClick={handleShow}>
          ☰
        </Button>

        {/* Offcanvas for mobile view */}
        <div className="d-lg-none ">
          <Offcanvas
            show={show}
            onHide={handleClose}
            responsive="lg"
            placement="end"
            className="offCanvas"
          >
            <div>
              <button
                className="btn-close text-white position-absolute fs-3 fw-bold top-0 end-0 m-3"
                onClick={handleClose}
              >
                <RxCross1 />
              </button>
              <Offcanvas.Header className="mobileMenu ">
                <Offcanvas.Title>
                  <Navlinks handleNavClick={handleClose} />
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <p className="mb-0"></p>
              </Offcanvas.Body>
            </div>
          </Offcanvas>
        </div>
      </div>

      {/* Desktop view components */}
      <div className="d-none d-lg-block ">
        <Navlinks />
      </div>
    </div>
  );
}

export default Navbar;
