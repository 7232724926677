import React from "react";
import "./dysautonomia.css";
import brain from "../../Assets/brain-removebg-preview.png";
import neurons from "../../Assets/neural networks 2.webp";
import neurons1 from "../../Assets/neurons-concept1.webp";
import headache from "../../Assets/headache.jpg";

function Dysautonomia() {
  return (
    <div>
      <div className="dysautomiaApproachIntroWrapper  position-relative ">
        <div className="dysautomiaApproachIntro  position-absolute end-0 d-flex align-items-center justify-content-center ">
          <div className="position-absolute  w-75">
            <div className="aboutDysautomia ">
              <h1>INTRODUCTION</h1>
              <div className="DysautomiaContent">
                The ANS is responsible for regulating various bodily functions
                autonomously. quasi. Veniam alias
                <div className="w-100 DysautomiaContentPara1">
                  aIt consists of the sympathetic nervous system (SNS) and the
                  parasympathetic nervous system (PSNS), which work in tandem to
                  maintain balance.
                  <div className="w-100 DysautomiaContentPara2">
                    The hypothalamus - central vagal axis (HPV axis) is crucial
                    in regulating the ANS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImpactAndHealth />
      <AutonomicImbalance />
      <TreatementApproaches />
    </div>
  );
}

export default Dysautonomia;

function ImpactAndHealth() {
  return (
    <div className="d-flex justify-content-center align-items-center ImpactAndHealth ">
      <div className="d-flex  flex-column justify-content-center imbalanceAndHealthWrapper">
        <div className="impactOfAutonomicImbalance ">
          <h1 className="my-2 my-sm-4">Impact of Autonomic Imbalance</h1>
          <div className="my-2 my-sm-4 py-2 mx-2 mx-sm-4">
            <p>
              Dysautonomia syndromes such as POTS, EM, RSD, orthostatic
              hypotension, and others can occur.
            </p>
            <p>
              Cognitive dysfunction disorders like ADD and autism may be linked
              to dysautonomia.
            </p>
            <p>
              Breathing and sleep disorders can also result from autonomic
              imbalance.
            </p>
          </div>
        </div>
        <div className="healthConditionsWithDysautonomia">
          <h1 className="my-2 my-sm-4">
            Health Conditions Associated with Dysautonomia
          </h1>
          <div className="my-2 my-sm-4 py-2 mx-2 mx-sm-4">
            <p>
              Dysautonomia may be at the root of conditions like type II
              diabetes, hypertension, congestive heart failure, PTSD, migraine,
              systemic inflammation, sleep apnea, chronic fatigue, and hormonal
              imbalances.
            </p>
            <p>
              This underscores the vital importance of maintaining autonomic
              balance for overall well-being.
            </p>
          </div>
        </div>
      </div>
      <div className="ImpAndHealthbrainWrapper d-flex justify-content-center">
        <img src={brain} alt="humanBrain" className="ImpAndHealthbrain" />
      </div>
    </div>
  );
}

function AutonomicImbalance() {
  return (
    <div className="d-flex justify-content-center align-items-center my-4 autonomicImbalanceWrapper">
      <div className="cognitiveDysfunctionWrapper d-flex flex-column align-items-center ">
        <img src={neurons} alt="" className="automicImbImg1" />
        <div className="cognitiveDysfunction">
          Cognitive dysfunction disorders like ADD and autism may be linked to
          dysautonomia. Breathing and sleep disorders can also result from
          autonomic imbalance. for this what imagte should be suggestable
        </div>
      </div>

      <div className="ImpOfAutonomicWrapper d-flex flex-column align-items-center">
        <div className="ImpOfAutonomic ">
          <h1>Impact of Autonomic Imbalance</h1>
          <div>
            Dysautonomia syndromes such as POTS, EM, RSD, orthostatic
            hypotension, and others can occur.
          </div>
        </div>
        <img src={neurons1} alt="" className="automicImbImg2" />
      </div>
    </div>
  );
}

function TreatementApproaches() {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center treatmentBlock pb-5">
        <div className="headacheWrapper d-flex justify-content-center">
          <img src={headache} alt="" />
        </div>
        <div className="d-flex flex-column TreatmentAppsWrapper">
          <div className="treatmentApps">
            <h1 className="py-2 py-sm-4">Treatment Approaches</h1>
            <p>
              Diagnosis involves monitoring physiological parameters like heart
              rate variability.
            </p>
            <p>
              Treatment protocols aim to reset the autonomic balance through
              medication, exercise, diet modifications, and breathing exercises.
            </p>
            <p>
              Specialized interventions, such as compression systems for
              cardiovascular conditions, may be incorporated into treatment
              plans.
            </p>
            <p>
              The RENU-CA research institute has developed successful protocols
              for managing dysautonomia and associated conditions.
            </p>
          </div>
          <div className="AutonomicImbConclusion">
            <h1>Conclusion</h1>
            <p>
              Addressing autonomic imbalance at its root cause can lead to
              improved health outcomes with reduced reliance on medications or
              medical devices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
