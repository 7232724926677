import "./contactForm.css";
import hospitalImg from "../../Assets/renucaHsptl.jpg";
import heartLogo from "../../Assets/heart bg.png";

function ContactForm() {
  const footerLinks = [
    {
      title: "About",
      links: ["Location", "Organization"],
    },
    {
      title: "International patients",
      links: [
        "why to the  renu-ca ?",
        "International Office Team",
        "Contact International Office",
      ],
    },
    {
      title: "Research",
      links: [
        "Clinical studies at the Renu-ca",
        "Research with data and sample",
      ],
    },
    {
      title: "Jobs",
      links: ["Open Positions"],
    },
  ];
  return (
    <div className="contactForm  d-flex flex-column ">
      <div className="d-md-flex justify-content-center ">
        <div className="hospitalImgSec m-md-4 text-start">
          <img src={hospitalImg} alt="hospital" className="mb-5" />
          <p>TELEPHONE:  (215) 785-9578</p>
          <p>FAX:  (215)-785-9579</p>
          <p>EMAIL: info@renu-ca.org</p>
          <p>ADDRESS: 501 Bath Road, Bristol PA, 19007</p>
        </div>
        <div className="mt-5 m-md-5 mt-md-4">
          <h3 className="text-white text-center text-md-start">| Contact Us</h3>
          <form action="" className="d-flex flex-column">
            <label htmlFor="name">Name</label>
            <input type="text" />
            <label htmlFor="name">Email</label>
            <input type="text" />
            <label htmlFor="name">Subject</label>
            <input type="text" />
            <label htmlFor="name">Message</label>
            <textarea type="text" />
          </form>
        </div>
      </div>
      <div className="d-flex mt-5 footerSec flex-column flex-md-row justify-content-md-between">
        <div className="text-center">
          <img src={heartLogo} alt="" className="footerLogo" />
          <h3 className="contactLogo d-flex flex-column">
            <span>RENUCA RESEARCH</span>
            <span>INSTITUTE</span>
          </h3>
        </div>
        <div className="d-md-flex">
          {footerLinks.map((LinkList) => (
            <div className="m-md-3">
              <h3 className="footerSideHeading">{LinkList.title}</h3>
              <ul className="footerLinks">
                {LinkList.links.map((link) => (
                  <li>{link}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
