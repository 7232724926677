import React from "react";
import "./coronory.css";
import coronaryIntervention from "../../Assets/coronaryIntervention.webp";
import impelllaDevice from "../../Assets/impelllaDevice.webp";
import EVAR from "../../Assets/EVAR.jpg";

function Coronory() {
  const Data = [
    {
      image: coronaryIntervention,
      heading:
        "Complex high risk coronary interventional procedures (CHIP): 1000+ cases including:",
      p1: "left main interventions",
      p2: "chronic total occlusions(CTO)",
      p3: "complex bifurcation stenosis",
      p4: "bypass graft disease with special interest in debulking atherectomy",
    },
    {
      image: impelllaDevice,
      heading:
        "Impella Pump - Percutaneous ventricular assist device (PVAD) procedures: 108 cases",
      p1: "acute coronary interventions for patients in cardiogenic shock",
      p2: "novel indications for Impella use in cardiomyopathy/congestive heart failure",
      p3: "Impella deployment in peripheral arterial disease(PAD) patients through innovative techniques",
    },
    {
      image: EVAR,
      heading:
        "Percutaneous approach with large bore access closure for AAA stent graft procedures: 50 cases",
      p1: "Novel large bore access closure solutions (Ramesh K. Adiraju, Access Closure Innovations, Journal of Indian College of Cardiology, Available online 1 July 2017, ISSN 1561-8811, https://doi.org/10.1016/j.jicc.2017.06.014.",
    },
  ];
  return (
    <div className="Urbanist">
      <div className="coronoyHeading">
        <h1 className="text-center">
          COMPLEX CORONARY, VALVULAR & ENDOVASCULAR INTERVENTIONS
        </h1>
      </div>

      <div className="corony">
        {Data.map((data) => {
          return (
            <div className="coronyCard d-md-flex bg-white">
              <div className="bg-white d-flex justify-content-center">
                <img src={data.image} className="coronoyImage" />
              </div>
              <div className="coronyCardData ">
                <h3 className="my-3">{data.heading}</h3>
                <ul>
                  <li>{data.p1}</li>
                  {data.p2 && <li>{data.p2}</li>}
                  {data.p3 && <li>{data.p3}</li>}
                  {data.p4 && <li>{data.p4}</li>}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      <div className="coronyApproach">
        <h2 className="py-3 text-center">
          Peripheral CHIP cases - limb salvage procedures with special emphasis
          on debulking atherectomy : 500+ cases
        </h2>
        <p className="text-md-center">
          Specialization in debulking atherectomy approach - coronary and
          peripheral for optimal revascularization and better long-term outcomes
          in complex disease states such as bifurcation stenosis, ostial
          stenosis, long diseased segments and in-stent re-stenosis where
          conventional approaches yield sub-optimal results.
        </p>
        <p className="text-md-center">
          Embolization therapy for life threatening bleeding cases. Embolization
          therapy experience published. Over 200 case experience with
          embolization therapy. (Endovascular Embolization Therapy – Abstract
          C-007 (CCI, SCAI Journal May 1, 2012 vol 79 issue 6) SCAI meetings May
          2012.)
        </p>
      </div>

      <div className="p-3 coronyLinks">
        <h2>LINKS</h2>
        <p className="ps-3 pt-1">
          <a
            href="https://www.sciencedirect.com/science/article/abs/pii/S1561881117301128"
            target="_blank"
            className="text-black text-decoration-none"
          >
            ◉ https://www.sciencedirect.com/science
          </a>
        </p>
      </div>
    </div>
  );
}

export default Coronory;
