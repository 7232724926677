import { NavLink } from "react-router-dom";
import "./navbar.css";

export function ResearchDropdown({ handleDropdownClick }) {
  return (
    <nav className="dropdown-links">
      <NavLink to="/dysautonomia" onClick={handleDropdownClick}>
        Dysautonomia Regulatory Approach
      </NavLink>
      <NavLink to="/discoveries" onClick={handleDropdownClick}>
        Discoveries and patents
      </NavLink>
      <NavLink to="/facultyPositions" onClick={handleDropdownClick}>
        Faculty positions and Clinical Assignments
      </NavLink>
      <NavLink
        to="/Coronory-Valvular-Endovascular"
        onClick={handleDropdownClick}
      >
        Coronory/Valvular/Endovascular
      </NavLink>
    </nav>
  );
}
