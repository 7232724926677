import "./App.css";
import Navbar from "./Routes/Navbar";
import PathConfig from "./Routes/PathConfig";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="routpages">
        <PathConfig />
      </div>
    </div>
  );
}

export default App;
