import { Routes, Route } from "react-router-dom";
import Home from "../Pages/HomePage";
import PostCovid from "../Components/postCovid/PostCovid";
import Coronory from "../Components/Coronory/Coronory";
import Discoveries from "../Components/Discoveries/Discoveries";
import Dysautonomia from "../Components/Dysautonomia/Dysautonomia";
import FacultyPositionsP from "../Components/FacultyPositions/FacultyPositions";

function PathConfig() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/postCovid" element={<PostCovid />} />
        <Route path="/Coronory-Valvular-Endovascular" element={<Coronory />} />
        <Route path="/discoveries" element={<Discoveries />} />
        <Route path="/dysautonomia" element={<Dysautonomia />} />
        <Route path="/facultyPositions" element={<FacultyPositionsP />} />
      </Routes>
    </div>
  );
}

export default PathConfig;
