import Home from "../Components/HomePage/Home";
import ContactForm from "../Components/HomePage/ContactForm";
function HomePage() {
  return (
    <div>
      <Home />
      <div id="contact">
        <ContactForm />
      </div>
    </div>
  );
}

export default HomePage;
