import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { ResearchDropdown } from "./ResearchDropDown";

function Navlinks({ handleNavClick }) {
  return (
    <nav className="justify-content-between d-flex flex-column flex-lg-row  align-items-center nav-links-top-list">
      <NavLink to="/" onClick={handleNavClick} className="mx-2 px-3 py-2">
        Home
      </NavLink>

      <div className="dropdown my-2 mx-2">
        <Link>
          <NavLink to="/dysautonomia" className="px-3 py-2">
            Research
          </NavLink>
          <div className="dropdown-content">
            <ResearchDropdown handleDropdownClick={handleNavClick} />
          </div>
        </Link>
      </div>

      <NavLink
        to="/postCovid"
        className="mx-2 px-3 py-2"
        onClick={handleNavClick}
      >
        Post Covid Syndrome
      </NavLink>
    </nav>
  );
}

export default Navlinks;
