import React from "react";
import "./discoveries.css";
import { TbNorthStar } from "react-icons/tb";
import heartValve from "../../Assets/heartValve.jpeg";

function Discoveries() {
  const Data = [
    {
      heading: "Vascular/Valvular Intervention Therapies:",
      text: "Investigating new approaches utilizing novel devices in coronary, peripheral vascular disease, and aortic valve disease.",
    },
    {
      heading: "Critical Limb Ischemia and Diabetic Foot Syndrome:",
      text: "Exploring novel approaches for limb salvage in critical limb ischemia, diabetic foot syndrome, and chronic stasis syndromes. Emphasizing a triple-pronged approach for vasculopathy, venous capillary insufficiency, and peripheral neuropathy in diabetic limb salvage programs.",
    },
    {
      heading: "Dysautonomia Syndromes:",
      text: "Discovering and applying Dysautonomia Syndromes such as Paradoxic Parasympathetic Syndrome (PPS), Hypervagotonia, and Autonomic Orthostasis in the evaluation and treatment of various conditions including diabetes, diabetic vasculopathy, nephropathy, cardiovascular complications, systemic inflammatory vasculopathy, coagulopathy, RSD/EM, Restless Leg Syndrome, Metabolic Syndrome, CFS, PTSD, ADD/ADHD, dilated cardiomyopathy, congestive heart failure, and cardiac arrhythmias.",
    },
    {
      heading: "Central Clinical Autonomic Loop:",
      text: "Identifying the central clinical autonomic loop as the central regulating mechanism for the autonomic system.",
    },
  ];

  const patents = [
    {
      name: "Patent 1",
      content:
        "Clover Cutting Balloon for Aortic Valvuloplasty - A minimally invasive procedure to treat aortic stenosis, a condition where the aortic valve becomes narrowed, restricting blood flow from the heart to the rest of the body.",
    },
    {
      name: "Patent 2",
      content:
        "Motorized Nitinol Guide Wire for Atherectomy - Medical device used in atherectomy procedures to facilitate the removal of plaque and other blockages from blood vessels.",
    },
    {
      name: "Patent 3",
      content:
        "Focused Cutting Balloon for Controlled PTCA (Percutaneous Transluminal Coronary Angioplasty)  to treat blocked or narrowed coronary arteries.",
    },
  ];

  return (
    <>
      <div className="discoveriesHeading">
        <div>
          <h1 className="fw-bold text-white inter">Welcome to our</h1>
          <h1 className="fw-bold text-white inter">Research Protocols</h1>
        </div>
        <p className="inter">
          Unlock endless opportunities in academia and industry
        </p>
        <button className="inter fw-bold mt-4">Join Now</button>
      </div>
      <div>
        {Data.map((data) => {
          return (
            <div className="discoveriesDesc">
              <h2 className="fw-bold inter">{data.heading}</h2>
              <p className="inter d-flex ">
                <div>
                  <TbNorthStar />
                </div>
                &nbsp;
                <div>{data.text}</div>
              </p>
            </div>
          );
        })}
        <img src={heartValve} alt="Heart Valve" className="heartValve" />
      </div>

      <div className="consultantResearch">
        <h1 className="inter fw-bold">Patented endovascular devices</h1>
        <p className="inter ms-2 Opportunities mb-5">
          Explore the various designed and patented endovascular devices
          awaiting to be developed for clinical use.
        </p>
        <div className="consultantResearchCards d-md-flex">
          {patents.map((data) => {
            return (
              <div className="consultantResearchCard me-md-5">
                <h3 className="inter fw-bold">{data.name}</h3>
                <p className="inter">{data.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Discoveries;
