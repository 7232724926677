import React from "react";
import "./facultyPositions.css";
import doctor1 from "../../Assets/doc1.webp";
import doctor2 from "../../Assets/doc2.webp";
import doctor4 from "../../Assets/doc11.webp";

function FacultyPositionsP() {
  return (
    <div>
      <div className="facPositionAndClinicalAsgWrapper d-flex justify-content-center align-items-center bg-dark ">
        <div className="facPositionAndClinicalAsg text-white   d-flex justify-content-center flex-column mx-4 py-4">
          <h1 className="p-3">Welcome to our professional network</h1>
          <p className="p-3">
            Unlock endless opportunities in academia and industry
          </p>
          <div>
            <button className="border-0 px-4 py-2 shadow-lg rounded-2 mx-3 my-2 bg-white facPosJoinNow">
              Join Now
            </button>
          </div>
        </div>
      </div>
      <Positions />
      <FacultyPositions />
      <ConsultantProctorOpp />
      <Awards />
    </div>
  );
}

export default FacultyPositionsP;

function Positions() {
  const symposiums = [
    {
      heading: "Faculty Positions",
      para: "Join our esteemed faculty and contribute to our mission  to our mission to our mission to our mission",
    },
    {
      heading: "Consultant/ Proctor",
      para: "Provide expert guidance and support to our students to our students to our students ",
    },
    {
      heading: "Featured Presentations",
      para: "Attend and participate in our engaging presentations Attend and participate in ",
    },
    {
      heading: "Faculty Memberships",
      para: "Become a member of our prestigious faculty community",
    },
    {
      heading: "Awards",
      para: "Recognize and celebrate excellence in our fieldRecognize and celebrate excellence in our field",
    },
  ];
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="symposiumsWrapper py-2 my-2 py-sm-4 my-sm-4">
          <h1>Positions/Symposiums</h1>
          <p>Explore our available positions and symposiums</p>
        </div>
        <div className="symposiumsCards d-flex flex-wrap justify-content-center py-sm-3 my-sm-3 py-1 my-1 ">
          {symposiums.map((symposium, index) => (
            <div key={index} className="symposiumsCard rounded-4">
              <h1>{symposium.heading}</h1>
              <p>{symposium.para}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function FacultyPositions() {
  const facultyPositions = [
    {
      image: doctor1,
      heading: "Directors",
      para: "As Director of Cardiac Catheterization Labs and Vascular Interventional Services, this cardiologist leads the department, ensuring advanced patient care and compliance with medical standards.",
    },
    {
      image: doctor2,
      heading: "Faculty",
      para: "As a Faculty member, this cardiologist educates and mentors medical professionals, contributing to the field through research and presentations at major conferences.",
    },
    {
      image: doctor4,
      heading: "Consultant",
      para: "As a Consultant for the IVT TEC thrombectomy device, this cardiologist provides expert guidance and training to optimize the use of cutting-edge medical technologies",
    },
  ];
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="symposiumsWrapper py-2 my-2 py-sm-4 my-sm-4">
          <h1>Faculty Positions</h1>
          <p>
            Explore the available faculty positions and join our esteemed team
            of professionals.
          </p>
        </div>
        <div className="facultyPositionsCards  d-flex flex-wrap justify-content-center py-sm-3 my-sm-3 py-1 my-1 ">
          {facultyPositions.map((facultyPosition, index) => (
            <div key={index} className="facultyPositionsCard rounded-4">
              <img
                src={facultyPosition.image}
                alt=""
                className="facultyImg rounded-5 w-100"
              />
              <h1 className="px-2 py-2">{facultyPosition.heading}</h1>
              <p className="px-2">{facultyPosition.para}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ConsultantProctorOpp() {
  const consultantSteps = [
    {
      heading: "Browse Available Opportunities",
      para: "Take a look at the different consultant and proctor positions we have available. Each position offers a unique opportunity to contribute to ",
    },
    {
      heading: "Apply for a Position",
      para: "Once you find a consultant or proctor position that interests you, submit your application through our online portal. We will review your application and get back to you as soon as possible.",
    },
    {
      heading: "Join Our Team",
      para: "If your application is successful, you will have the opportunity to join our team of consultants and proctors. You will work closely with our event organizers and contribute to the smooth  ",
    },
  ];
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="symposiumsWrapper  py-2 my-2 py-sm-4 my-sm-4">
        <h1>Consultant/Proctor Opportunities</h1>
        <p>
          Explore the various consultant and proctor opportunities available.
          Join our team and contribute to the success of our events.
        </p>
      </div>
      <div className="consultantOppCards  d-flex flex-wrap justify-content-center py-sm-3 my-sm-3 py-1 my-1 ">
        {consultantSteps.map((steps, index) => (
          <div className="consultantOppCard ">
            <h1>
              <span className="steps"> Step{index}:</span>
              <span className="stepHeading">{steps.heading}</span>
            </h1>
            <p>{steps.para}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function Awards() {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center awardsWrapper">
        <h1 className="py-2 my-2">Awards</h1>
        <div className="awardsList p-3">
          <div className="d-flex ">
            <span className="px-3 pb-3">➊</span>
            <p>
              Top 10 Cardiologists in Delaware Valley: This is an award
              recognizing Dr. [Name] as one of the top ten cardiologists
            </p>
          </div>
          <div className="d-flex ">
            <span className="px-3 pb-3">➋</span>
            <p>
              Recognized as one of the top ten cardiologists in the Delaware
              Valley area, acknowledging excellence in cardiology practice.
            </p>
          </div>
          <div className="d-flex ">
            <span className="px-3 pb-3">➌</span>
            <p>
              Recipient of the Patients' Choice Physician Awards in 2008, 2010,
              2012, 2016, and 2017, based on patient reviews and ratings,
              highlighting high levels of patient satisfaction and quality care.
            </p>
          </div>
        </div>
        <button className="border-0 px-3 py-2 bg-dark text-white shadow-lg rounded-2 learnMoreBtn my-sm-5 my-2">
          Learn More
        </button>
      </div>
    </div>
  );
}
